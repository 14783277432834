<template>
  <b-container class="overflow-auto" fluid>
    <!-- Publisher Interface controls -->
    <a @click="goBack" style="border: 1px black"><span class="fa fa-long-arrow-left"></span> Back</a>
    <h5 class="my-3">Showing RTB Zones for publisher:
      <strong>{{ typeof (rtbzone.list[0]) === 'undefined' ? '' : rtbzone.list[0].PublisherName }}</strong>
    </h5>
    <a-row>
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-rtb-zones'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-rtbzones-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :sort-compare="sortCompare" :bordered="true" :current-page="currentPage" :fields="filteredFields"
      :filter="filter" :hover="true" :items="showRTBZones" :per-page="perPage" :small="true" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :striped="true" :tbody-tr-class="rowClass" show-empty
      stacked="md" @filtered="onFiltered">
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleRTBzoneStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Campaigns)="data">
        <div v-if="data.item.Campaigns.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="campaign.Id" v-for="campaign in campaigns">
                  {{
      data.item.Campaigns.includes(campaign.Id) ? `[${campaign.Id}] ${campaign.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Campaigns.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Nativeads)="data">
        <div v-if="data.item.Nativeads.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in nativeads">
                  {{
      data.item.Nativeads.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Nativeads.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Remotertbzones)="data">
        <div v-if="data.item.Remotertbzones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in remotertbzones">
                  {{
      data.item.Remotertbzones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Remotertbzones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(Dsppopfeeds)="data">
        <div v-if="data.item.Dsppopfeeds.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="feed.Id" v-for="feed in dsppopfeeds">
                  {{
      data.item.Dsppopfeeds.includes(feed.Id) ? `[${feed.Id}] ${feed.Name}` : ''
    }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Dsppopfeeds.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(SubidListMode)="data">
        {{ data.item.SubidListMode ? 'Whitelist' : 'Blacklist' }}
      </template>
      <template #cell(AutoOptimize)="data">
        {{ data.item.AutoOptimize === 1 ? '✔' : '' }}
      </template>
      <template #cell(PopAdmXml)="data">
        {{ data.item.PopAdmXml === 1 ? '✔' : '' }}
      </template>
      <template #cell(BannerEnabled)="data">
        {{ data.item.BannerEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(VideoEnabled)="data">
        {{ data.item.VideoEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AudioEnabled)="data">
        {{ data.item.AudioEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(NativeEnabled)="data">
        {{ data.item.NativeEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(PopEnabled)="data">
        {{ data.item.PopEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(WebEnabled)="data">
        {{ data.item.WebEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AppEnabled)="data">
        {{ data.item.AppEnabled === 1 ? '✔' : '' }}
      </template>
      <template #cell(AvgResponseTimeMs)="data">
        {{ data.item.AvgResponseTimeMs }} ms
      </template>
      <template #cell(actions)="data">
        <div class="flex">
          <b-button @click="showModal(data.item)" class="btn-info mr-1" size="sm">
            <i class="fa fa-info"></i>
          </b-button>
          <router-link class="add-button" @click.stop
            :to="{ name: 'rtbzone-update', params: { id: data.item.Id }, }">
            <b-button class="btn-info mr-1" size="sm" @click="editRTBzone(data.item.Id)">
              <i class="fa fa-edit"></i>
            </b-button>
          </router-link>
          <b-button class="btn-info mr-1" size="sm" @click="copyRTBzone(data.item.Id, data.item)">
            <i class="fa fa-copy"></i>
          </b-button>
        </div>
      </template>
      <template #cell(Margin)="data">
        {{ data.item.Margin }}%
      </template>
      <template #cell(PublisherRevShare)="data">
        {{ data.item.PublisherRevShare }}%
      </template>
      <template #cell(ImpressionTrackingMethod)="data">
        {{ impressionTrackingMethod[data.item.ImpressionTrackingMethod] }}
      </template>
      <template #cell(CheckAdsTxt)="data">
        {{ txtOptions[data.item.CheckAdsTxt] }}
      </template>
      <template #cell(CheckAppAdsTxt)="data">
        {{ txtOptions[data.item.CheckAppAdsTxt] }}
      </template>
      <template #cell(CheckSellersJson)="data">
        {{ txtOptions[data.item.CheckSellersJson] }}
      </template>
    </b-table>
    <b-modal id="bv-modal-example" hide-footer size="lg" body-class="rtb-modal" class="rtb-modal">
      <template #modal-title>
        RTB Zone Info
      </template>
      <div class="d-block" style="min-height: 500px">
        <div>
          <a-tabs default-active-key="1" class="mb-5">
            <a-tab-pane key="1" tab="URL Template">
              <div class="card">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  RTB Zone URL
                </div>
                <div class="card-body p-3">
                  <a-tooltip placement="bottom">
                    <template slot="title">
                      <span>Copy URL</span>
                    </template>
                    <a-input-search :value="modal.zoneUrl" placeholder="" @focus="selectUrl"
                      @change="$event.target.value = modal.zoneUrl" @search="copyUrl">
                      <template #enterButton>
                        <a-button @click="copyUrl(modal.zoneUrl)"><i class="fa fa-copy"></i></a-button>
                      </template>
                    </a-input-search>
                  </a-tooltip>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Publisher Reports">
              <div class="card mb-2">
                <div class="card-header p-3" style="background-color: rgba(237, 237, 243, 0.38)">
                  By Date
                </div>
                <div class="card-body p-3">
                  <a-input-search :value="modal.reportsByDate" placeholder="" @focus="selectUrl"
                    @change="$event.target.value = modal.reportsByDate" @search="copyUrl">
                    <template #enterButton>
                      <a-button @click="copyUrl(modal.reportsByDate)"><i class="fa fa-copy"></i></a-button>
                    </template>
                  </a-input-search>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
      (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
        perPage).toLocaleString()
    }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows" class="my-0"></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button v-if="publisherStatus === 0" class="btn btn-success px-5" @click="redirectToCreateRtbzone()">Create RTB
          Zone
        </button>
        <b-alert show variant="danger" v-else> Publisher is Deactivated</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneRTBZone } from '@/api/display/rtbzone'
import { getPublishers } from '@/api/display/publisher'
import { getNativeAdsShort } from '@/api/display/nativead'
import { getRemoteRTBZonesShort } from '@/api/display/remotertbzone'
import { getDspPopFeedsShort } from '@/api/display/dsppopfeed'
import { getCampaignsShort } from '@/api/display/campaign'

export default {
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      selectedPublisher: [],
      publisherStatus: 0,
      modal: {
        zoneUrl: '',
        reportsByDate: '',
        reportsBySubid: '',
        reportsByCountry: '',
      },
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          headerTitle: 'Actions',
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'Id',
          label: 'Zone ID',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: true,
        },
        {
          key: 'PublisherName',
          label: 'Publisher',
          show: true,
          headerTitle: 'Publisher Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'PlatformDomain',
          label: 'Platform Domain',
          show: true,
          headerTitle: 'Platform Domain',
          sortable: true,
        },
        {
          key: 'SellersJsonDomain',
          label: 'sellers.json Domain',
          show: true,
          headerTitle: 'sellers.json Domain',
          sortable: true,
        },
        {
          key: 'Campaigns',
          label: 'Campaigns',
          show: true,
          headerTitle: 'Campaigns',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 100px',
        },
        {
          key: 'Remotertbzones',
          label: 'Remote RTB Zones',
          show: true,
          headerTitle: 'Remote RTB Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 150px',
        },
        {
          key: 'Dsppopfeeds',
          label: 'DSP Pop Feeds',
          show: true,
          headerTitle: 'DSP Pop Feeds',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 120px',
        },
        {
          key: 'RequestsDailyLimit',
          label: 'Requests Daily Limit',
          headerTitle: 'Requests Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:160px',
          summed: false,
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          headerTitle: 'Bids Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ImpressionsDailyLimit',
          label: 'Imp. Daily Limit',
          headerTitle: 'Impressions Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'SpentDailyLimit',
          label: 'Spent Daily Limit',
          headerTitle: 'Spent Daily Limit',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatCurrency',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'ImpressionsLimitPerIp',
          label: 'Imp. Limit Per IP',
          headerTitle: 'Impressions Limit Per IP',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'BannerEnabled',
          label: 'Banner',
          show: true,
          headerTitle: 'Banner Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:60px',
        },
        {
          key: 'VideoEnabled',
          label: 'Video',
          show: true,
          headerTitle: 'Video Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'AudioEnabled',
          label: 'Audio',
          show: true,
          headerTitle: 'Audio Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'NativeEnabled',
          label: 'Native',
          show: true,
          headerTitle: 'Native Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'PopEnabled',
          label: 'Pop',
          show: true,
          headerTitle: 'Pop Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'WebEnabled',
          label: 'Web',
          show: true,
          headerTitle: 'Web Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'AppEnabled',
          label: 'App',
          show: true,
          headerTitle: 'App Enabled',
          sortable: false,
          class: 'text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'MinBidfloor',
          label: 'Min. Bidfloor',
          headerTitle: 'Min. Bidfloor',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:110px',
        },
        {
          key: 'MaxBidfloor',
          label: 'Max. Bidfloor',
          headerTitle: 'Max. Bidfloor',
          show: false,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-md-right text-center',
          formatter: '$formatCurrency',
          thStyle: 'width:110px',
        },
        {
          key: 'Margin',
          label: 'Margin',
          show: true,
          headerTitle: 'Margin',
          sortable: true,
          thClass: 'text-center',
          thStyle: 'width:75px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'PublisherRevShare',
          label: 'Rev. Share',
          show: true,
          headerTitle: 'Publisher Revenue Share',
          sortable: true,
          thClass: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'ImpressionTrackingMethod',
          label: 'ITM',
          show: false,
          headerTitle: 'Impression Tracking Method',
          sortable: true,
          thStyle: 'width:120px',
        },
        {
          key: 'CheckAdsTxt',
          label: 'ads.txt',
          show: false,
          headerTitle: 'ads.txt',
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'CheckAppAdsTxt',
          label: 'app-ads.txt',
          show: false,
          headerTitle: 'app-ads.txt',
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'CheckSellersJson',
          label: 'sellers.json',
          show: false,
          headerTitle: 'sellers.json',
          sortable: true,
          thStyle: 'width:150px',
        },
        {
          key: 'MaxQps',
          label: 'Max. QPS',
          headerTitle: 'Maximum queries per second',
          show: true,
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:90px',
          summed: false,
        },
        {
          key: 'Qps',
          label: 'QPS',
          show: true,
          headerTitle: 'Queries per second',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:70px',
        },
        {
          key: 'AvgResponseTimeMs',
          label: 'Avg. Time',
          headerTitle: 'Daily average response time in milliseconds',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:100px',
          tdClass: 'text-md-right text-center',
        },
        {
          key: 'AutoOptimize',
          label: 'AutoOpt',
          show: true,
          headerTitle: 'AutoOpt',
          sortable: true,
          class: 'text-center',
          thStyle: 'width:85px',
        },
        {
          key: 'PopAdmXml',
          label: 'Pop adm XML',
          show: true,
          headerTitle: 'Pop adm XML',
          sortable: true,
          class: 'text-center',
          thStyle: 'width:120px',
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          thStyle: 'width:80px',
          class: 'text-center',
          sortable: false,
          headerTitle: 'Active',
        },
      ],
      campaigns: null,
      nativeads: null,
      remotertbzones: null,
      dsppopfeeds: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      impressionTrackingMethod: ['nurl', 'burl', 'Markup Display'],
      txtOptions: ['No', 'Yes, allow supplier until crawled', 'Yes, deny supplier until crawled'],
    }
  },
  computed: {
    ...mapState(['rtbzone', 'settings']),
    ...mapGetters('rtbzone', ['getRTBZones', 'getActiveRTBZones']),
    showActive: {
      get: function () {
        return this.settings.showActiveRTBZones
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'showActiveRTBZones',
          value,
        })
      },
    },
    showRTBZones() {
      return this.showActive ? this.getActiveRTBZones : this.getRTBZones
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showRTBZones.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Nativeads' || key === 'Remotertbzones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    selectUrl(e) {
      e.target.select()
      e.stopPropagation()
    },
    copyUrl(link) {
      navigator.clipboard.writeText(link)
    },
    redirectToCreateRtbzone() {
      // this.$router.push('/display/rtbzone/create/' + this.$route.params.id)
      this.$router.push({
        name: 'create-rtbzone-pubId',
        params: { publisherId: this.$route.params.id },
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    getPublishers() {
      this.$store.dispatch('rtbzone/LOAD_RTBZONES_FOR_USER_PUB', { AccManagerId: '0', PublisherId: this.$route.params.id })
    },
    getRTBzone(id) {
      cloneRTBZone(id).then(response => {
        if (response) {
          // this.$router.push('/display/rtbzone/update/' + response.Rtbzone.Id)
          this.$router.push({
            name: 'rtbzone-update',
            params: { id: response.Rtbzone.Id },
          }).catch(() => {
          })
          this.$notification.success({
            message: 'RTB Zone Cloned',
            description: `RTB Zone: ${response.Rtbzone.Name} has been successfully cloned.`,
          })
        }
      })
    },
    copyRTBzone(id, rtbzone) {
      Modal.confirm({
        title: 'Are you sure you want to clone RTB Zone?',
        content: h => <div>{status} Clone RTB Zone: <strong>{rtbzone.Name}</strong>?</div>,
        onOk: function () {
          this.getRTBzone(id)
        }.bind(this),
      })
    },
    showModal(item) {
      this.$bvModal.show('bv-modal-example')
      this.modal.zoneUrl = item.ZoneUrl
      this.modal.reportsByDate = item.StatsByDate
      this.modal.reportsBySubid = item.ReportsBySubid
      this.modal.reportsByCountry = item.ReportsByCountry
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editRTBzone(id) {
      // this.$router.push('/display/rtbzone/update/' + id)
      this.$router.push({
        name: 'rtbzone-update',
        params: { id: id },
      }).catch(() => {
      })
    },
    toggleRTBzoneStatus(rtbzone) {
      const status = rtbzone.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change RTB Zone status?',
        content: h => <div>{status} RTB Zone <strong>{rtbzone.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = rtbzone.Status === 0 ? 1 : 0
          this.$store.dispatch('rtbzone/CHANGE_STATUS', {
            id: rtbzone.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_FOR_USER_PUB', { AccManagerId: '0', PublisherId: this.$route.params.id }).then(res => {
    })
    getPublishers(this.$route.params.id).then(response => {
      if (response) {
        this.publisherStatus = response.Status
      }
    })
    getNativeAdsShort()
      .then(res => {
        this.nativeads = res
      })
    getRemoteRTBZonesShort()
      .then(res => {
        this.remotertbzones = res
      })
    getDspPopFeedsShort()
      .then(res => {
        this.dsppopfeeds = res
      })
    getCampaignsShort()
      .then(res => {
        this.campaigns = res
      })
  },
}
</script>
<style scoped>
.rtb-modal {
  color: red;
}

.parameter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.parameter-key {
  width: 20%;
  font-weight: 600;
}

.parameter-value {
  width: 80%;
  text-align: left;
}

.parameters-title {
  font-size: 140%;
  font-style: bold;
}

@media only screen and (max-width: 992px) {
  .parameter-value {
    width: 75%;
    text-align: left;
  }
}
</style>
